<template>
  <div style="color: #000;height: 100%;">
    <van-nav-bar title="二手车质量检测报告" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div style="height: 100%;" class="tophes">
      <van-row class="vanrow fw" style="border-top: 1px solid #000;">
        <van-col span="8" class="cet ris ">车牌号码</van-col>
        <van-col span="16" class="pd_12">{{carNo}}</van-col>
      </van-row>
      <van-row class="vanrow fw">
        <van-col span="8" class="cet ris ">车型</van-col>
        <van-col span="16" class="pd_12">{{carBrand}}-{{standard}}</van-col>
      </van-row>
      <van-row class="vanrow fw">
        <van-col span="8" class="cet ris fw">车架号</van-col>
        <van-col span="16" class="pd_12">{{vin}}</van-col>
      </van-row>
      <van-row class="vanrow fw">
        <van-col span="3" class="cet ">序号</van-col>
        <van-col span="18" class="cet ris lif fw">检测项目名称</van-col>
        <van-col span="3" class="cet fw">结果</van-col>
      </van-row>
      <van-row class="vanrow fw" v-for="(item,idx) in list.checkItems" :key="idx">
        <van-col span="3" class="cet ">{{idx+1}}</van-col>
        <van-col span="18" class="ris lif ">{{item.checkName}}</van-col>
        <van-col span="3" class="cet ">合格</van-col>
      </van-row>
      <div class="jianche">
        <div>
          <span style="vertical-align: middle">检测人(签名)：</span>

          <img class="qianimg" :src="checkSign" alt />
        </div>
        <div>检测门店：</div>
        <div>检测日期：{{list.checkDate}}</div>
        <img class="hegeimg" src="../../assets/img/hege.png" alt />
      </div>
      <div style="height: 10px;"></div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
import { checkdetails } from "../../api/check.js";
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      actv: 1,
      list: {},
      id: this.$route.query.id,
      checkDate: this.$route.query.checkDate,
      carNo: this.$route.query.carNo,
      carBrand: this.$route.query.carBrand,
      standard: this.$route.query.standard,
      vin: this.$route.query.vin,
      checkSign: this.$route.query.checkSign
    };
  },
  methods: {
    onClickRight() {
      this.$router.push({
        path: "Add_car"
      });
    },
    tab(e) {
      this.actv = e;
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      const data = {
        id: this.id
      };
      checkdetails(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    onLoads() {
      // this.page++;
      this.onLoad();
    },

    back() {
      this.$router.push({
        path: "user"
      });
      // this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.tophes {
  padding: 55px 12px;
  font-size: 13px;
  color: #000;
}
.vanrow {
  line-height: 30px;
  border: 1px solid #000;
  border-top: none;
}
.cet {
  text-align: center;
}
.ris {
  border-right: 1px solid #000;
}
.lif {
  border-left: 1px solid #000;
  padding: 0 10px;
}
.pd_12 {
  padding: 0 12px;
}
.jianche {
  font-weight: bold;
  line-height: 36px;
  position: relative;
  margin-top: 10px;

}

.fw {
  font-weight: bold;
}
.hegeimg {
  position: absolute;
  width: 135px;
  top: 0;
  margin-left: 120px;
  margin-top: 30px;
}
.qianimg {
  height: 40px;

  vertical-align: middle
}
</style>
<style >
.van-nav-bar__title {
  color: #000;
  font-weight: bold;
}
</style>